var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bg-modal',{attrs:{"desktop-size":"sm","title":_vm.modalTitle,"value":_vm.isOpen},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"flex align-center justify-end"},[_c('bg-button',{staticClass:"mr-16",attrs:{"disabled":_vm.loading,"data-testid":"kembali-btn"},on:{"click":_vm.closeModal}},[_vm._v(" Kembali ")]),_c('bg-button',{attrs:{"variant":"primary","loading":_vm.loading,"data-testid":"simpan-btn"},on:{"click":_vm.handleSave}},[_vm._v(" Simpan ")])],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"name":_vm.isAdditionalIncome ? 'Nama Pendapatan' : 'Nama Biaya',"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('bg-field',{attrs:{"label":_vm.isAdditionalIncome ? 'Nama Pendapatan' : 'Nama Biaya',"error":!!errors.length,"message":errors[0]}},[_c('bg-input',{attrs:{"placeholder":_vm.isAdditionalIncome
              ? 'Masukkan nama pendapatan'
              : 'Masukkan nama biaya',"data-testid":"cost-name"},model:{value:(_vm.cost.name),callback:function ($$v) {_vm.$set(_vm.cost, "name", $$v)},expression:"cost.name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Kuantitas","rules":"required|numeric|max:3|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('bg-field',{attrs:{"label":"Kuantitas","error":!!errors.length,"message":errors[0]}},[_c('bg-input',{attrs:{"placeholder":_vm.isAdditionalIncome
              ? 'Masukkan kuantitas pendapatan'
              : 'Masukkan kuantitas biaya',"data-testid":"cost-qty"},model:{value:(_vm.cost.qty),callback:function ($$v) {_vm.$set(_vm.cost, "qty", $$v)},expression:"cost.qty"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Harga Satuan","rules":"required|numeric|min_value:1|max_value:5000000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('bg-field',{attrs:{"label":"Harga Satuan","error":!!errors.length,"message":errors[0]}},[_c('bg-input',{attrs:{"prefix":"Rp","data-testid":"cost-amount"},model:{value:(_vm.cost.amount),callback:function ($$v) {_vm.$set(_vm.cost, "amount", $$v)},expression:"cost.amount"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }