<template>
  <bg-modal
    desktop-size="sm"
    :title="modalTitle"
    :value="isOpen"
    @close="closeModal"
  >
    <ValidationObserver ref="observer">
      <ValidationProvider
        :name="isAdditionalIncome ? 'Nama Pendapatan' : 'Nama Biaya'"
        rules="required|max:50"
        v-slot="{ errors }"
      >
        <bg-field
          :label="isAdditionalIncome ? 'Nama Pendapatan' : 'Nama Biaya'"
          :error="!!errors.length"
          :message="errors[0]"
        >
          <bg-input
            v-model="cost.name"
            :placeholder="
              isAdditionalIncome
                ? 'Masukkan nama pendapatan'
                : 'Masukkan nama biaya'
            "
            data-testid="cost-name"
          />
        </bg-field>
      </ValidationProvider>

      <ValidationProvider
        name="Kuantitas"
        rules="required|numeric|max:3|max_value:100"
        v-slot="{ errors }"
      >
        <bg-field
          label="Kuantitas"
          :error="!!errors.length"
          :message="errors[0]"
        >
          <bg-input
            v-model="cost.qty"
            :placeholder="
              isAdditionalIncome
                ? 'Masukkan kuantitas pendapatan'
                : 'Masukkan kuantitas biaya'
            "
            data-testid="cost-qty"
          />
        </bg-field>
      </ValidationProvider>

      <ValidationProvider
        name="Harga Satuan"
        rules="required|numeric|min_value:1|max_value:5000000"
        v-slot="{ errors }"
      >
        <bg-field
          label="Harga Satuan"
          :error="!!errors.length"
          :message="errors[0]"
        >
          <bg-input
            v-model="cost.amount"
            prefix="Rp"
            data-testid="cost-amount"
          />
        </bg-field>
      </ValidationProvider>
    </ValidationObserver>

    <template #footer>
      <div class="flex align-center justify-end">
        <bg-button
          class="mr-16"
          :disabled="loading"
          data-testid="kembali-btn"
          @click="closeModal"
        >
          Kembali
        </bg-button>
        <bg-button
          variant="primary"
          :loading="loading"
          data-testid="simpan-btn"
          @click="handleSave"
        >
          Simpan
        </bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
import { BgInput, BgModal, BgButton, BgField } from 'bangul-vue';
import { currencyFormatter } from 'Utils/formatter';
import detailTransferApi, { endpoints } from '@admin_endpoints/detail-transfer';

export default {
  name: 'OperationalModal',

  components: {
    BgInput,
    BgModal,
    BgButton,
    BgField,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    costData: {
      type: Object,
      default: () => ({}),
    },
    isAdditionalIncome: {
      type: Boolean,
      default: false,
    },
  },

  filters: {
    rupiah: currencyFormatter,
  },

  data() {
    return {
      loading: false,
      cost: {
        name: '',
        qty: null,
        amount: null,
      },
    };
  },

  computed: {
    isEditSection() {
      return !!Object.keys(this.costData).length;
    },
    modalTitle() {
      let string = '';
      if (this.isAdditionalIncome) {
        string = this.isEditSection ? 'Ubah Pendapatan' : 'Tambah Pendapatan';
      } else {
        string = this.isEditSection
          ? 'Ubah Biaya Operasional'
          : 'Tambah Biaya Operasional';
      }
      return string;
    },
    approvalId() {
      return this.$route.params.approval_id;
    },
  },

  watch: {
    costData: {
      immediate: true,
      deep: true,
      handler(cost) {
        if (Object.keys(cost).length) {
          this.cost = { ...cost };
        }
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
      this.$emit('update:costData', {});
      this.cost = {
        name: '',
        qty: null,
        amount: null,
      };
    },

    async handlePostAdditionalIncome(approvalId, params) {
      this.loading = true;

      const response = await endpoints.postAdditionalIncome(approvalId, params);

      this.loading = false;

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        this.$emit('action-success');
        this.$toast.show('Perubahan berhasil disimpan.');
        this.closeModal();
      }
    },

    async handlePutAdditionalIncome(approvalId, additionalIncomeId, params) {
      this.loading = true;

      const response = await endpoints.putAdditionalIncome(
        approvalId,
        additionalIncomeId,
        params
      );

      this.loading = false;

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        this.$emit('action-success');
        this.$toast.show('Perubahan berhasil disimpan.');
        this.closeModal();
      }
    },

    async handleSave() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        if (this.isAdditionalIncome) {
          if (this.isEditSection) {
            this.updateIncome();
          } else {
            this.addIncome();
          }
        } else if (this.isEditSection) {
          this.updateCost();
        } else {
          this.addCost();
        }
      }
    },

    async addCost() {
      this.loading = true;

      try {
        await detailTransferApi.postOperationalCost({
          approval_id: this.approvalId,
          list_cost: this.cost,
        });

        this.$emit('action-success');
        this.$toast.show('Biaya pengurangan operasional berhasil ditambahkan.');
        this.closeModal();
      } catch (error) {
        console.error(error);
        this.$toast.show(
          'Terjadi kesalahan saat menambahkan biaya pengurangan operasional.'
        );
      } finally {
        this.loading = false;
      }
    },

    async updateCost() {
      if (!this.cost.cost_id) return;

      this.loading = true;

      const data = { ...this.cost };
      delete data.total_amount;

      try {
        await detailTransferApi.updateOperationalCost({
          approval_id: this.approvalId,
          cost_id: data.cost_id,
          list_cost: this.cost,
        });

        this.$emit('action-success');
        this.$toast.show('Perubahan berhasil disimpan.');
        this.closeModal();
      } catch (error) {
        console.error(error);
        this.$toast.show('Terjadi kesalahan saat menyimpan.');
      } finally {
        this.loading = false;
      }
    },

    async addIncome() {
      await this.handlePostAdditionalIncome(this.approvalId, {
        name: this.cost.name,
        qty: Number(this.cost.qty),
        amount: Number(this.cost.amount),
      });
    },

    async updateIncome() {
      await this.handlePutAdditionalIncome(
        this.approvalId,
        this.cost.income_id,
        {
          name: this.cost.name,
          qty: Number(this.cost.qty),
          amount: Number(this.cost.amount),
        }
      );
    },
  },
};
</script>
